import {
  ReactElement,
  FC,
  useState,
  useEffect,
  useCallback,
  SetStateAction,
  Dispatch
} from 'react'
import { Calendar, dateFnsLocalizer } from 'react-big-calendar'
import { useQuery } from '@apollo/client'
import { AVAILABLE_SLOTS_FOR_RANGE } from '../../graphql/AvailableSlotsForRange'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import startOfWeek from 'date-fns/startOfWeek'
import getDay from 'date-fns/getDay'
import enUS from 'date-fns/locale/en-US'
import {
  EventType,
  CalendarEventType, HEX
} from '../types'
import { useCurrentWeek } from '../../hooks/useCurrentWeek'
import { useUrlParams } from '../../hooks/useUrlParams'
import {
  getMaxLimit,
  formatEvents,
  formatStartEnd,
  getDayLimit,
  getColorLimit,
  getColorsToLimit
} from './WeekViewUtil'
import { CustomToolbar } from './CustomToolbar/CustomToolbar'
import { CalendarEvent } from './CalendarEvent/CalendarEvent'
import { BottomBar } from './BottomBar/BottomBar'
import NotFound from '../NotFound/NotFound'
import styles from './WeekView.module.scss'

const locales = {
  'en-US': enUS
}

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales
})

interface IProps {
  selectedEvents: EventType[]
  setSelectedEvents: Dispatch<SetStateAction<EventType[]>>
  setTab: () => void
}

const WeekView: FC<IProps> = ({
  selectedEvents,
  setSelectedEvents,
  setTab
}): ReactElement => {
  const {
    providerId,
    providerIds,
    apptTypeIds,
    orgLevel,
    colors,
    maxAppt,
    maxApptColor,
    maxApptDay,
    limitColors
  } = useUrlParams()
  const { currentStartDate, currentEndDate } = useCurrentWeek()
  const [events, setEvents] = useState<EventType[]>([])
  const [colorsToLimit, setColorsToLimit] = useState<HEX[]>([])

  const { loading, data, fetchMore } = useQuery(AVAILABLE_SLOTS_FOR_RANGE, {
    variables: {
      providerId,
      providerIds,
      apptTypeIds,
      startDate: currentStartDate,
      endDate: currentEndDate,
      canJoinWaitList: true,
      orgLevel
    }
  })

  useEffect(() => {
    if (!loading && (Boolean(data))) {
      const updatedEvents = formatEvents(
        data.availableSlotsForRange,
        apptTypeIds,
        colors
      )
      setEvents(updatedEvents)
      setColorsToLimit(getColorsToLimit(updatedEvents, limitColors))
    }
  }, [data, loading])

  const refetchData = (date: Date): void => {
    const { startDate, endDate } = formatStartEnd(date)
    fetchMore({
      variables: {
        startDate,
        endDate
      }
    }).then((response) => {
      const updatedEvents = formatEvents(
        response.data.availableSlotsForRange,
        apptTypeIds,
        colors
      )
      setEvents(updatedEvents)
      setColorsToLimit(getColorsToLimit(updatedEvents, limitColors))
    }).catch(e => console.error(e))
  }

  const eventPropGetter = useCallback((event: EventType): {
    className?: string
    dataFor?: string
    style?: {backgroundColor?: string, borderColor?: string}
  } => {
    const isSelected = selectedEvents.some(selected => event.id === selected.id)
    const isAtMax = getMaxLimit(
      selectedEvents,
      maxAppt
    )
    const disableColor = getColorLimit(
      selectedEvents,
      event,
      maxApptColor,
      colorsToLimit
    )
    const disableDay = getDayLimit(
      selectedEvents,
      event,
      maxApptDay,
      colorsToLimit
    )
    const maxLimit = isAtMax || (disableColor || disableDay) ? 'maxLimit' : ''
    const noWaitList = event.notBookable ? 'notBookable' : ''
    const unSelected = `${maxLimit} ${noWaitList}`
    const className = event.isWaitListed ? `waitList selected ${noWaitList}` : `selected ${noWaitList}`
    const customStyle = { backgroundColor: event.color, borderColor: event.color }

    return {
      dataFor: event.id,
      style: { ...customStyle },
      className: unSelected,
      ...(isSelected && {
        className
      })
    }
  }, [selectedEvents])

  const onSelectEvent = (event: EventType): void => {
    if (event.notBookable) return
    const isAtMax = getMaxLimit(
      selectedEvents,
      maxAppt
    )
    const disableColor = getColorLimit(
      selectedEvents,
      event,
      maxApptColor,
      colorsToLimit
    )
    const disableDay = getDayLimit(
      selectedEvents,
      event,
      maxApptDay,
      colorsToLimit
    )
    const currentEvents = new Array(...selectedEvents)
    const isAlreadySelected = currentEvents.some((selected: EventType) => event.id === selected.id)

    const updatedSelected = isAlreadySelected
      ? currentEvents.filter(selected => event.id !== selected.id)
      : isAtMax || (disableColor || disableDay)
        ? currentEvents
        : [...currentEvents, event]
    const formatSelected = updatedSelected.map(s => {
      return {
        ...s,
        isSelected: true
      }
    })

    // finding the unselected events from events state
    const unSelectedEvents = [...events].filter(event => {
      return formatSelected.find(selected => selected.id === event.id) == null
    })
    // formatting unselected events to change isSelected value to false
    const formatEvents = unSelectedEvents.map(event => {
      return {
        ...event,
        isSelected: false
      }
    })

    setEvents([...formatEvents, ...formatSelected])
    setSelectedEvents(formatSelected)
  }

  if (providerId == null || apptTypeIds.length === 0) {
    const error = providerId == null
      ? 'A provider ID is needed in the URL to load available appointments.'
      : apptTypeIds.length === 0
        ? 'Appointment type ID is needed in the URL to load available appointments.'
        : 'Oops something went wrong.'
    return <div className="col-xs-9 row middle-xs center-xs"><NotFound error={error}/></div>
  }

  return (
    <div className={`${styles.calendarContainer} col-sm-9 col-xs-12`}>
      <Calendar
        defaultView='week'
        views={['week']}
        events={events}
        localizer={localizer}
        style={{ height: '100vh' }}
        eventPropGetter={eventPropGetter}
        timeslots={1}
        // only way to disable the default tooltip in react-big-calendar
        tooltipAccessor={() => ''}
        onSelectEvent={onSelectEvent}
        components={{
          toolbar: (toolbar) => <CustomToolbar toolbar={toolbar} refetchData={refetchData} />,
          event: (eventValues: CalendarEventType) => <CalendarEvent {...eventValues}/>
        }}
      />
      {selectedEvents.length > 0
        ? <BottomBar onClick={setTab}/>
        : null}
    </div>
  )
}
export default WeekView
