import { FC, ReactElement, useMemo } from 'react'
import Tab from '../../Components/Tab/Tab'
import { EventType, TAB_TYPES } from '../types'
import styles from './SideNavigation.module.scss'

interface IProps {
  title: String
  selectedEvents: EventType[]
  tab: TAB_TYPES
  setTab: (val: TAB_TYPES) => void
}
const SideNavigation: FC<IProps> = ({
  title,
  selectedEvents,
  tab,
  setTab
}): ReactElement => {
  const appointmentsLength = useMemo(() => selectedEvents.filter(d => !d.isWaitListed).length, [selectedEvents])
  const waitListLength = useMemo(() => selectedEvents.filter(d => d.isWaitListed).length, [selectedEvents])
  const appointment = appointmentsLength > 1 ? 'Appointments' : 'Appointment'
  const waitList = waitListLength > 1 ? 'Waitlists' : 'Waitlist'
  return (
    <div className={`col-sm-3 col-xs-12 ${styles.sideNav}`}>
      <h2>{title}</h2>
      <div className={styles.tabContainer}>
        <Tab
          text="Select Appointment"
          disabled={false}
          onClick={() => {
            setTab(TAB_TYPES.WEEK)
          }}
          number={1}
        />
        <div className={styles.appointmentsList}>
          <p>{(appointmentsLength > 0) ? `${appointmentsLength} ${appointment}` : ''}</p>
          <p>{(waitListLength > 0) ? `${waitListLength} ${waitList}` : ''}</p>
        </div>
        <Tab
          text="Your Information"
          disabled={tab === TAB_TYPES.WEEK && selectedEvents.length === 0}
          onClick={() => setTab(TAB_TYPES.INFORMATION)}
          number={2}
        />
      </div>
    </div>
  )
}

export default SideNavigation
