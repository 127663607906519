import { FC, ReactElement } from 'react'
import styles from './Button.module.scss'

interface IProps {
  text?: string
  icon?: JSX.Element
  onClick?: () => void
  disabled?: boolean
}
const SecondaryButton: FC<IProps> = ({ text, icon, onClick, disabled }): ReactElement => {
  if ((icon != null) && (text == null)) {
    return <button className={`${styles.btnIcon} ${styles.btn} ${styles.btnSecondary}`} onClick={onClick} disabled={disabled}>
      {icon}
    </button>
  } else {
    return <button className={`${styles.btn} ${styles.btnSecondary}`} onClick={onClick} disabled={disabled}>
      {text}
    </button>
  }
}

export default SecondaryButton
