import { FC, ReactElement } from 'react'
import styles from './Tab.module.scss'

interface IProps {
  text: String
  onClick: () => void
  disabled: boolean
  number: Number
}

const Tab: FC<IProps> = ({ text, number, disabled, onClick }): ReactElement => {
  return (
    <button onClick={onClick} disabled={disabled} className={styles.tab}>
      <span className={styles.number}>{number}</span>
      {text}
    </button>
  )
}

export default Tab
