interface ILocationSpotIcon {
  width: string|number|undefined
  height: string|number|undefined
  color: string
}

export default ({ width = '19px', height = '24px', color = '#999999' }: ILocationSpotIcon): JSX.Element => (
  <svg width={width} height={height} viewBox="0 0 19 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Book-Appointment---Web" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="D-&gt;-Client-&gt;-Booking-&gt;-Confirmation" transform="translate(-429.000000, -497.000000)" fill={color}>
        <g id="Stacked-Group" transform="translate(428.000000, 61.000000)">
          <g id="Group-4" transform="translate(0.000000, 240.000000)">
            <g id="Stacked-Group-2" transform="translate(0.000000, 34.000000)">
              <g id="Stacked-Group-5" transform="translate(1.000000, 162.000000)">
                <path d="M9.5,10.5482363 C10.8116763,10.5482363 11.875,9.47277917 11.875,8.14613561 C11.875,6.81949204 10.8116763,5.74403494 9.5,5.74403494 C8.18832372,5.74403494 7.125,6.81949204 7.125,8.14613561 C7.125,9.47277917 8.18832372,10.5482363 9.5,10.5482363 Z M8.656875,18.8474941 C8.87984784,19.0748681 9.18336793,19.2027627 9.5,19.2027627 C9.81663207,19.2027627 10.1201522,19.0748681 10.343125,18.8474941 L15.2,13.9231877 C17.5069115,11.5911718 18.1975309,8.08316982 16.9497593,5.03528258 C15.7019878,1.98739534 12.7616132,0 9.5,0 C6.2383868,0 3.2980122,1.98739534 2.05024067,5.03528258 C0.802469138,8.08316982 1.49308848,11.5911718 3.8,13.9231877 L8.656875,18.8474941 Z M3.835625,7.59365245 C3.9999463,5.85936472 4.92933822,4.292703 6.365,3.32992377 C8.26820807,2.0660323 10.7317919,2.0660323 12.635,3.32992377 C14.0607964,4.28921391 14.9870228,5.84378659 15.1594667,7.56697325 C15.3319105,9.29015991 14.7324543,11.000916 13.525625,12.2297067 L9.5,16.3012674 L5.474375,12.2297067 C4.26596743,11.0126483 3.66401539,9.30971473 3.835625,7.59365245 L3.835625,7.59365245 Z M17.8125,21.5978993 L1.1875,21.5978993 C0.53166186,21.5978993 0,22.1356279 0,22.7989497 C0,23.4622714 0.53166186,24 1.1875,24 L17.8125,24 C18.4683381,24 19,23.4622714 19,22.7989497 C19,22.1356279 18.4683381,21.5978993 17.8125,21.5978993 Z" id="Shape"/>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
