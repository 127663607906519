import { gql } from '@apollo/client'

export const AVAILABLE_SLOTS_FOR_RANGE = gql`
    query GetAvailableSlotsForRange(
        $apptTypeIds: [ID],
        $startDate: String,
        $endDate: String,
        $providerId: String,
        $providerIds: [String],
        $orgLevel: Boolean,
        $canJoinWaitList: Boolean,
    ) {
        availableSlotsForRange(
            appointment_type_ids: $apptTypeIds,
            start_date: $startDate,
            end_date: $endDate,
            provider_id: $providerId,
            provider_ids: $providerIds,
            org_level: $orgLevel,
            clients_can_join_waitlist: $canJoinWaitList,
        ){
            appointment_id
            date
            has_waitlist_enabled
            is_fully_booked
            color,
            appointment_type {
              id
              length
              name
            }
        }
    }
`
