import { ChangeEvent, ReactElement, FC } from 'react'
import styles from './Input.module.scss'

interface IProps {
  label: String
  name: string
  value: string | number | readonly string[] | undefined
  placeholder: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  required: boolean
  disabled: boolean
}

const Input: FC<IProps> = ({
  label,
  name,
  value,
  onChange,
  required,
  disabled,
  placeholder
}): ReactElement => {
  return (
    <>
      <label className={`${disabled ? styles.disabled : ''} ${styles.label}`} htmlFor={name}>
        {label}{required ? '*' : ''}
      </label>
      <input
        className={styles.input}
        name={name}
        type='text'
        value={value}
        required={required}
        disabled={disabled}
        placeholder={placeholder}
        onChange={(e) => onChange(e)}
      />
    </>
  )
}

export default Input
