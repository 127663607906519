import { ReactElement, FC } from 'react'
import { ToolbarProps, NavigateAction } from 'react-big-calendar'
import startOfWeek from 'date-fns/startOfWeek'
import SecondaryButton from '../../../Components/Button/SecondaryButton'
import ChevronLeft from '../../../assets/icons/ChevronLeft'
import ChevronRight from '../../../assets/icons/ChevronRight'
import { useCurrentWeek } from '../../../hooks/useCurrentWeek'
import styles from './CustomToolbar.module.scss'

interface IProps {
  toolbar: ToolbarProps<Event, object>
  refetchData: (value: Date) => void
}
export const CustomToolbar: FC<IProps> = ({ toolbar, refetchData }): ReactElement => {
  const { now, currentStartDate } = useCurrentWeek()
  const goToBack = (): void => {
    const mDate = toolbar.date
    const newDate = new Date(
      mDate.getFullYear(),
      mDate.getMonth(),
      mDate.getDate() - 7,
      1)
    toolbar.onNavigate('prev' as NavigateAction, newDate)
    refetchData(newDate)
  }

  const goToNext = (): void => {
    const mDate = toolbar.date
    const newDate = new Date(
      mDate.getFullYear(),
      mDate.getMonth(),
      mDate.getDate() + 7,
      1)
    toolbar.onNavigate('next' as NavigateAction, newDate)
    refetchData(newDate)
  }

  const goToToday = (): void => {
    toolbar.onNavigate('today' as NavigateAction, now)
    refetchData(now)
  }

  const disablePrev = (): boolean => {
    const date = startOfWeek(toolbar.date)
    const startDate = date.toISOString().split('T')[0]
    return currentStartDate === startDate
  }

  return (
            <div className={`row middle-xs ${styles.toolBarContainer}`}>
                <h3 className='col-sm-8 col-xs-12'>{toolbar.label} {toolbar.date.getFullYear()}</h3>
                <div className={`col-sm-4 col-xs-12 end-sm ${styles.btnGroup}`}>
                    <SecondaryButton
                        icon={<ChevronLeft width={'10px'} height={'14px'}/>}
                        onClick={goToBack}
                        disabled={disablePrev()}
                    />
                    <SecondaryButton onClick={goToToday} text="This Week"/>
                    <SecondaryButton
                        icon={<ChevronRight width={'10px'} height={'14px'}/>}
                        onClick={goToNext}
                    />
                </div>
            </div >
  )
}
