import startOfWeek from 'date-fns/startOfWeek'
import endOfWeek from 'date-fns/endOfWeek'
import addMinutes from 'date-fns/addMinutes'
import sub from 'date-fns/sub'
import { AvailableSlotType, EventType, HEX } from '../types'

interface DateColor{
  date: string
  color: HEX
}
interface GroupedReturnValues {
  [key: string]: DateColor[]
}

export const formatStartEnd = (date: Date): {startDate: String, endDate: String} => {
  const newStart = startOfWeek(date)
  const end = endOfWeek(date)
  const newEnd = sub(end, { days: 0 }) // needed as when converted toISoString changes date to next day.
  const startDate = newStart.toISOString().split('T')[0]
  const endDate = newEnd.toISOString().split('T')[0]
  return { startDate, endDate }
}

export const formatEvents = (
  availableSlotsForRange: AvailableSlotType[],
  apptTypeIds: string[],
  colorsParams: HEX[]
): EventType[] => {
  if (apptTypeIds.length === 0) return []
  // Making sure the number of colors is lesser or equal to type of appointments
  const colorsParamsArray = colorsParams.length > apptTypeIds.length
    ? colorsParams.splice(0, apptTypeIds.length)
    : colorsParams

  return availableSlotsForRange.map((appointment: AvailableSlotType) => {
    const start = new Date(appointment.date)
    const end = addMinutes(start, appointment.appointment_type.length)
    const notBookable = !appointment.has_waitlist_enabled && appointment.is_fully_booked
    const isWaitListed = appointment.has_waitlist_enabled && appointment.is_fully_booked
    const colorIndex = apptTypeIds.indexOf(appointment.appointment_type.id)

    return {
      id: appointment.appointment_id,
      apptTypeId: appointment.appointment_type.id,
      title: appointment.appointment_type.name,
      duration: appointment.appointment_type.length,
      start,
      end,
      isSelected: false,
      isWaitListed,
      notBookable,
      color: colorsParams.length > 0
        ? colorsParamsArray[colorIndex].toUpperCase() as HEX
        : (appointment.color != null)
            ? appointment.color.toUpperCase() as HEX
            : '#32629B'
    }
  })
}

export const getColorsToLimit = (events: EventType[], limitColors: HEX[]): HEX[] => {
  if (limitColors.length > 0) {
    return limitColors
  }
  const eventColors = events.map(event => event.color)
  const filterUndefinedColors: HEX[] = eventColors.filter(c => c !== undefined)
  const uniqueColors = filterUndefinedColors.filter((color, index, self) => self.indexOf(color) === index)
  return uniqueColors
}

const groupBy = (list: DateColor[], getKey: (item: DateColor) => string): GroupedReturnValues =>
  list.reduce<GroupedReturnValues>((previous, currentItem) => {
    const group = getKey(currentItem)
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!(previous[group])) previous[group] = []
    previous[group].push(currentItem)
    return previous
  }, {})

export const getDayLimit = (
  selectedEvents: EventType[],
  event: EventType,
  maxApptDay: number | null,
  colorsToLimit: HEX[]
): boolean => {
  const formatSelectedEvents = selectedEvents.map(e => {
    return {
      color: e.color,
      date: e.start?.toDateString() ?? ''
    }
  })
  const dates = formatSelectedEvents.map(event => event.date)
  const groupedSelectedEventsByDates: GroupedReturnValues = groupBy(formatSelectedEvents, formatSelectedEvents => formatSelectedEvents.date)

  const datesWithMaxColorSelected = dates.filter((date) => {
    return colorsToLimit.length > 0
      ? colorsToLimit.some(color => groupedSelectedEventsByDates[date].filter(event => event.color === color).length === maxApptDay)
      : date
  })
  const eventDate = event.start?.toDateString()
  const colorsForCurrentSelectedDate = groupedSelectedEventsByDates[(eventDate != null) ? eventDate : '']?.map(e => e.color)
  return datesWithMaxColorSelected.length > 0 && (eventDate != null)
    ? datesWithMaxColorSelected.includes(eventDate) && colorsToLimit.includes(event.color) && colorsForCurrentSelectedDate.includes(event.color)
    : false
}

export const getColorLimit = (
  selectedEvents: EventType[],
  event: EventType,
  maxApptColor: number|null,
  colorsToLimit: Array<HEX | undefined>
): boolean => {
  if (selectedEvents.length === 0) return false
  // Needed to allow the groupBy function to work properly, and remove key not needed for comparison.
  const formatSelectedEvents = selectedEvents.map(e => {
    return {
      color: e.color,
      date: e.start?.toDateString() ?? ''
    }
  })
  const filterEventsWithColors = formatSelectedEvents.filter(e => colorsToLimit.includes(e.color))
  const groupByColor = groupBy(filterEventsWithColors, filterEventsWithColors => filterEventsWithColors.color)
  return (maxApptColor !== null) && colorsToLimit.length > 0
    ? colorsToLimit.some(color => groupByColor[event.color]?.filter(e => e.color === color).length === maxApptColor)
    : false
}

export const getMaxLimit = (
  selectedEvents: EventType[],
  maxAppt: number|null
): boolean => {
  return (maxAppt !== null)
    ? selectedEvents.length === maxAppt
    : false
}
