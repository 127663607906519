import { FC, ReactElement } from 'react'
import Input from '../../../Components/Input/Input'
import PrimaryButton from '../../../Components/Button/PrimaryButton'
import ChevronRight from '../../../assets/icons/ChevronRight'
import styles from '../Information.module.scss'

interface IProps {
  firstName: string
  lastName: string
  email: string
  setFirstName: (val: string) => void
  setLastName: (val: string) => void
  setEmail: (val: string) => void
  submit: () => Promise<void>
}
export const InformationForm: FC<IProps> = ({
  firstName,
  lastName,
  email,
  setFirstName,
  setLastName,
  setEmail,
  submit
}): ReactElement => {
  const disableButton = firstName.length === 0 || lastName.length === 0 || email.length === 0
  return (
    <>
      <h3 className={`col-xs-12 ${styles.title}`}>Add Your Information</h3>
      <div className='col-xs-6'>
        <Input
          label={'First Name'}
          name={'firstName'}
          value={firstName}
          placeholder={'Ex: Mary'}
          onChange={(e) => setFirstName(e.target.value)}
          required={true}
          disabled={false}/>
      </div>
      <div className='col-xs-6'>
        <Input
          label={'Last Name'}
          name={'lastName'}
          value={lastName}
          placeholder={'Ex: Smith'}
          onChange={(e) => setLastName(e.target.value)}
          required={true}
          disabled={false}/>
      </div>
      <div className='col-xs-12'>
        <Input
          label={'Registered Email Address'}
          name={'email'}
          value={email}
          placeholder={'Ex: mary@example.com'}
          onChange={(e) => setEmail(e.target.value)}
          required={true}
          disabled={false}/>
      </div>
      <div className={`col-xs-12 ${styles.buttonContainer}`}>
        <PrimaryButton
          text='Book Sessions'
          icon={<ChevronRight width={'10px'} height={'14px'}/>}
          disabled={disableButton}
          onClick={submit}
        />
      </div>
    </>
  )
}
