import { FC, ReactElement } from 'react'
import format from 'date-fns/format'
import AppointmentIcon from '../../../../assets/icons/AppointmentIcon'
import CalendarIcon from '../../../../assets/icons/CalendarIcon'
import ClockIcon from '../../../../assets/icons/ClockIcon'
import LocationSpotIcon from '../../../../assets/icons/LocationSpotIcon'
import { AppointmentConfirmationType } from '../../../types'
import styles from '../Confirmation.module.scss'

interface IProps {
  appointment: AppointmentConfirmationType
}
export const AppointmentDetails: FC<IProps> = ({ appointment }): ReactElement => {
  const date = new Date(appointment.date)
  const formatDate = format(date, 'EEEE - MMM d, yyyy')
  const start = new Date(appointment.start)
  const end = new Date(appointment.end)
  const startTime = format(start, 'hh:mm aa')
  const endTime = format(end, 'hh:mm aa')
  return (
    <div className={`row ${styles.detail}`}>
      <div className={`col-xs-12 ${styles.iconText}`}>
        <AppointmentIcon width={'20px'} height={'20px'} color={'#828282'}/>
        <p>{appointment.title}</p>
      </div>
      <div className={`col-xs-12 ${styles.iconText}`}>
        <CalendarIcon width={'20px'} height={'20px'} color={'#828282'} opacity={'1'} />
        <p>{formatDate}</p>
      </div>
      <div className={`col-xs-12 ${styles.iconText}`}>
        <ClockIcon width={'20px'} height={'20px'} color={'#828282'} opacity={'1'} />
        <p>{startTime} - {endTime} ({appointment.timezone_abbr})</p>
      </div>
      <div className={`col-xs-12 ${styles.iconText}`}>
        <LocationSpotIcon width={'20px'} height={'20px'} color={'#828282'} />
        <p>{appointment.contact_type}</p>
      </div>
    </div>
  )
}
