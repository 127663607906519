import { FC, ReactElement } from 'react'
import CheckMark from '../../../assets/icons/CheckMark'
import { AppointmentConfirmationType } from '../../types'
import { AppointmentDetails } from './AppointmentDetails/AppointmentDetails'
import styles from './Confirmation.module.scss'

interface IProps {
  appointments: AppointmentConfirmationType[]
}

export const Confirmation: FC<IProps> = ({ appointments }): ReactElement => {
  return (
    <div className={`row ${styles.card}`}>
      <h3 className={`col-xs-12 ${styles.iconText}`}>
        <CheckMark size={'40px'} color={'#7ABC32'} strokeCircleW={'2px'} />
        Your booking has been submitted.
      </h3>

      <h6 className={'col-xs-12'}>Details</h6>
      {appointments.map((appointment: AppointmentConfirmationType, i: number) => <AppointmentDetails key={`${i}-${appointment.id}`} appointment={appointment}/>)}
      <p className={`col-xs-12 ${styles.emailConfirmation}`}>You'll receive an email confirmation.</p>
    </div>
  )
}
