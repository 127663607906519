import { ReactElement } from 'react'
import ReactTooltip from 'react-tooltip'
import { formatTime } from './CalendarEventUtil'
import { CalendarEventType } from '../../types'
import ClockIcon from '../../../assets/icons/ClockIcon'
import styles from './CalendarEvent.module.scss'

export const CalendarEvent = (eventsValue: CalendarEventType): ReactElement => {
  const time = formatTime(eventsValue.event.start, eventsValue.event.end)
  const title = eventsValue.title.length > 16 ? `${eventsValue.title.substring(0, 16)}...` : eventsValue.title
  return (
    <>
        <div data-tip='' data-for={eventsValue.event.id}>
          <div className="row">
            <p className={`${styles.eventTitle} col-xs-10`}>{title}</p>
            {eventsValue.event.notBookable && <span className={`${styles.isFull} col-xs-2 end-xs`}> Full</span>}
            {eventsValue.event.isSelected && eventsValue.event.isWaitListed
              ? <span className={`${styles.icon} col-xs-2 end-xs`}>
                    <ClockIcon width='10px' height='10px' opacity='1' color={'#fff'} />
                  </span>
              : null}
          </div>
          <div className="row start-xs">
            <div className={`${styles.time} col-sm-9 col-xs-10`}>
              <p>{time}</p>
              {eventsValue.event.duration > 45 ? <p>{eventsValue.event.duration} min</p> : null}
            </div>
            <div className={`${styles.checkbox} col-sm-3 col-xs-2 end-xs`}>
              <div className='checkMark' />
            </div>
          </div>
        </div>
      <ReactTooltip id={eventsValue.event.id} effect='solid' className={styles.tooltip}>
          <p className={styles.tooltipTitle}>{title}</p>
          <p>{time}</p>
          <p>{eventsValue.event.duration} min</p>
      </ReactTooltip>
    </>
  )
}
