import { FC, ReactElement } from 'react'
import WarningIcon from '../../../assets/icons/WarningIcon'
import styles from './Error.module.scss'

interface IProps {
  message: String
}

export const Error: FC<IProps> = ({ message }): ReactElement => {
  return (
    <div className={styles.card}>
      <h3 className={`col-xs-12 ${styles.iconText}`}>
        <WarningIcon width={'40px'} height={'40px'} color={'#FFAF1E'}/>
        Oops, something went wrong.
      </h3>
      <p>{message}</p>
    </div>
  )
}
