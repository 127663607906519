interface IClockIcon {
  width?: string|number|undefined
  height?: string|number|undefined
  color?: string
  opacity?: string
}
export default ({ width = '14px', height = '14px', color = '#4A90E2', opacity = '0.2' }: IClockIcon): JSX.Element => (
  <svg width={width} height={height} viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Calendar---Add-Appointment/Availability" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="D-&gt;-Calendar-&gt;-Provider-&gt;-Blocked-Availability" transform="translate(-26.000000, -114.000000)">
        <g id="Group-2" transform="translate(25.000000, 88.000000)">
          <g transform="translate(1.000000, 26.000000)">
            <path d="M7,-3.10862447e-16 C3.13400675,-3.10862447e-16 6.21724894e-16,3.13400675 6.21724894e-16,7 C6.21724894e-16,10.8659932 3.13400675,14 7,14 C10.8659932,14 14,10.8659932 14,7 C14,5.14348457 13.2625021,3.36300718 11.9497475,2.05025253 C10.6369928,0.737497883 8.85651543,-3.10862447e-16 7,-3.10862447e-16 Z M7,12.6 C3.9072054,12.6 1.4,10.0927946 1.4,7 C1.4,3.9072054 3.9072054,1.4 7,1.4 C10.0927946,1.4 12.6,3.9072054 12.6,7 C12.6,8.48521234 12.0100017,9.90959426 10.959798,10.959798 C9.90959426,12.0100017 8.48521234,12.6 7,12.6 Z M9.17,7.441 L7.7,6.594 L7.7,3.5 C7.7,3.11340068 7.38659932,2.8 7,2.8 C6.61340068,2.8 6.3,3.11340068 6.3,3.5 L6.3,7 C6.3,7 6.3,7.056 6.3,7.084 C6.30414481,7.13223142 6.31596005,7.17949237 6.335,7.224 C6.34941575,7.26553139 6.36818699,7.30542026 6.391,7.343 C6.41015495,7.38278769 6.43362952,7.42034701 6.461,7.455 L6.573,7.546 L6.636,7.609 L8.456,8.659 C8.56268204,8.71946631 8.68337653,8.75084688 8.806,8.75001729 C9.12477505,8.75222874 9.40479483,8.53879546 9.48713825,8.23083108 C9.56948167,7.9228667 9.43334886,7.59816268 9.156,7.441 L9.17,7.441 Z" id="Shape" fill={color} fill-opacity={opacity}/>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
