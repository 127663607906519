interface ICheckMark {
  size: string | number
  strokeCircleW: string | number | undefined
  color: string | undefined
}
export default ({ size = '32px', strokeCircleW = '2', color = '#D0D0D0' }: ICheckMark): JSX.Element => (
  <div className="checkmark-circle-icon" style={{ height: size }}>
    <svg width={size} height={size} viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink">
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Icon/Goals/outlined" fillRule="nonzero">
          <g id="Check-Copy">
            <path d="M20,1 C9.50228475,1 1,9.50228475 1,20 C1,30.4912468 9.50581512,39 20,39 C30.4877153,39 39,30.4877153 39,20 C39,9.50581512 30.4912468,1 20,1 Z" id="Shape-Copy-16" stroke={color} strokeWidth={strokeCircleW} className="circle"/>
            <polygon id="Path" fill={color} points="16.5 29 9 20.8386942 11.1225 18.5290446 16.5 24.3807009 27.8775 12 30 14.3096495" className="checkmark"/>
          </g>
        </g>
      </g>
    </svg>
  </div>
)
