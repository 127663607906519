import {
  ApolloClient,
  InMemoryCache
} from '@apollo/client'

const uri = process.env.NODE_ENV === undefined || process.env.NODE_ENV === 'development'
  ? 'https://staging-api.gethealthie.com/graphql'
  : 'https://api.gethealthie.com/graphql'

export const client = new ApolloClient({
  uri,
  cache: new InMemoryCache()
})
