interface IWarningIcon {
  width: string|number|undefined
  height: string|number|undefined
  color: string
}
export default ({ width = '14px', height = '13px', color = '#4A90E2' }: IWarningIcon): JSX.Element => (
  <svg className="warning-icon" width={width} height={height} viewBox="0 0 22 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="UI-Icons" transform="translate(-714.000000, -1170.000000)" fill={color}>
        <g id="Group-Copy-46" transform="translate(289.000000, 1169.000000)">
          <g id="Icon/User-Interface/Warning" transform="translate(424.000000, 0.000000)">
            <path d="M22.2636452,18.2583076 C22.9365929,19.4768291 22.09189,21 20.7478175,21 L3.2519787,21 C1.90531763,21 1.06451578,19.4744678 1.73615101,18.2583076 L10.4841798,2.41349139 C11.1574556,1.19439863 12.8437624,1.19660761 13.5158352,2.41349139 L22.2636452,18.2583076 Z M12.0000075,15 C11.0737838,15 10.3229246,15.7508592 10.3229246,16.6770829 C10.3229246,17.6033066 11.0737838,18.3541657 12.0000075,18.3541657 C12.9262312,18.3541657 13.6770903,17.6033066 13.6770903,16.6770829 C13.6770903,15.7508592 12.9262312,15 12.0000075,15 Z M10.4077631,8.37800864 L10.678211,13.3363406 C10.690862,13.5683613 10.8827057,13.7499967 11.1150546,13.7499967 L12.8849603,13.7499967 C13.1173092,13.7499967 13.3091529,13.5683613 13.321804,13.3363406 L13.5922518,8.37800864 C13.6059237,8.12739413 13.4063873,7.91666502 13.1554082,7.91666502 L10.8445703,7.91666502 C10.5935912,7.91666502 10.3940913,8.12739413 10.4077631,8.37800864 L10.4077631,8.37800864 Z" id="Shape"/>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
