import { Event } from 'react-big-calendar'
export type HEX = `#${Uppercase<string>}`

export interface EventType extends Event{
  id: string
  apptTypeId: string
  title: string
  isSelected: boolean
  isWaitListed: boolean
  notBookable: boolean
  duration: number
  color: HEX
}
export interface CalendarEventType{
  title: string
  event: EventType
}

export interface AppointmentType {
  id: string
  name: string
  length: number
}

export interface AvailableSlotType {
  appointment_id: string
  date: Date
  has_waitlist_enabled: boolean
  is_fully_booked: boolean
  appointment_type: AppointmentType
  color?: HEX
  __typename: String
}

export enum TAB_TYPES {
  'WEEK'= 'week',
  'INFORMATION'= 'information'
}

export interface ProviderType {
  full_name: String
}

export interface AppointmentConfirmationType {
  id: string
  title: String
  contact_type: String
  date: string
  end: string
  start: string
  timezone_abbr: String
  provider: ProviderType
}
