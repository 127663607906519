import { FC, ReactElement, useState } from 'react'
import { EventType } from '../types'
import { useMutation } from '@apollo/client'
import { COMPLETE_CHECKOUT } from '../../graphql/CompleteCheckout'
import { InformationForm } from './InformationForm/InformationForm'
import { Confirmation } from './Confirmation/Confirmation'
import { Error } from './Error/Error'
import styles from './Information.module.scss'
import { useUrlParams } from '../../hooks/useUrlParams'

interface IProps {
  selectedEvents: EventType[]
  setSelectedEvents: (value: []) => void
}

const Information: FC<IProps> = ({ selectedEvents, setSelectedEvents }): ReactElement => {
  const { providerId } = useUrlParams()
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [isConfirmed, setIsConfirmed] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [completeCheckout, { data, error }] = useMutation(COMPLETE_CHECKOUT)

  const submit = async (): Promise<void> => {
    const apptIds = selectedEvents.map(event => event.id)
    const values = {
      apptIds,
      firstName,
      lastName,
      email,
      providerId
    }
    try {
      await completeCheckout({ variables: { ...values } })
      setHasError(false)
      setIsConfirmed(true)
      setSelectedEvents([])
    } catch (e) {
      console.error(e)
      setIsConfirmed(false)
      setHasError(true)
      setSelectedEvents([])
    }
  }

  return (
    <div className="col-sm-9 col-xs-12">
      <div className={styles.formContainer}>
        <div className='row'>
          {!isConfirmed && !hasError && <InformationForm
            firstName={firstName}
            lastName={lastName}
            email={email}
            setFirstName={setFirstName}
            setLastName={setLastName}
            setEmail={setEmail}
            submit={submit}
          />}
          {isConfirmed && <Confirmation appointments={data.completeCheckout.appointments}/>}
          {hasError && <Error message={error?.message !== undefined ? error.message : ''}/>}
        </div>
      </div>
    </div>
  )
}

export default Information
