import { FC, ReactElement } from 'react'
import PrimaryButton from '../../../Components/Button/PrimaryButton'
import ChevronRight from '../../../assets/icons/ChevronRight'
import styles from './BottomBar.module.scss'

interface IProps {
  onClick: () => void
}
export const BottomBar: FC<IProps> = ({ onClick }): ReactElement => {
  return (
    <div className={`${styles.bottomBar} col-md-9 col-xs-12`}>
      <PrimaryButton
        text="Select and Continue"
        icon={<ChevronRight width={'10px'} height={'14px'}/>}
        onClick={onClick}
      />
    </div>
  )
}
