import { ReactElement, useState } from 'react'
import { EventType, TAB_TYPES } from './types'
import WeekView from './WeekView/WeekView'
import SideNavigation from './SideNavigation/SideNavigation'
import Information from './Information/Information'

const BookSessions = (): ReactElement => {
  const [tab, setTab] = useState<TAB_TYPES>(TAB_TYPES.WEEK)
  const [selectedEvents, setSelectedEvents] = useState<EventType[]>([])

  return (
    <div className="row page">
      <SideNavigation
        title='Book Group Sessions'
        tab={tab}
        setTab={setTab}
        selectedEvents={selectedEvents}
      />
      {tab === TAB_TYPES.WEEK
        ? <WeekView
          selectedEvents={selectedEvents}
          setTab={() => setTab(TAB_TYPES.INFORMATION)}
          setSelectedEvents={setSelectedEvents}
        />
        : null}
      {tab === TAB_TYPES.INFORMATION
        ? <Information
          selectedEvents={selectedEvents}
          setSelectedEvents={setSelectedEvents}
        />
        : null}
    </div>
  )
}

export default BookSessions
