import { HEX } from '../Views/types'

interface UrlParamsValues {
  providerId: string | null
  providerIds: string[] | null
  apptTypeIds: string[]
  orgLevel: boolean
  colors: HEX[]
  maxAppt: number | null
  maxApptColor: number | null
  limitColors: HEX[]
  maxApptDay: number | null
}
export const useUrlParams = (): UrlParamsValues => {
  const searchParamString = window.location.search
  const paramObject = new URLSearchParams(searchParamString)
  const orgLevel = Boolean(paramObject.get('org_level'))
  const providerId = paramObject.get('dietitian_id')

  const providerIdsString = paramObject.get('provider_ids')
  const providerIds = (providerIdsString != null)
    ? JSON.parse(providerIdsString).map(String)
    : null

  const apptTypeIdsString = paramObject.get('appt_type_ids')
  const apptTypeIds = (apptTypeIdsString != null)
    ? JSON.parse(apptTypeIdsString).map(String)
    : []

  const colorStrings = paramObject.get('colors')
  const colorsArray = (colorStrings != null)
    ? JSON.parse(colorStrings.replaceAll('\'', '"')).map(String)
    : []
  const colors = colorsArray.map((color: string) => `#${color.toUpperCase()}`)

  const max = paramObject.get('max_appt')
  const maxAppt = max !== null
    ? parseFloat(max)
    : null

  const maxColorParam = paramObject.get('max_appt_color')
  const maxApptColor = maxColorParam !== null
    ? parseFloat(maxColorParam)
    : null

  const maxDayParam = paramObject.get('max_appt_day')
  const maxApptDay = maxDayParam !== null
    ? parseFloat(maxDayParam)
    : null

  const colorsLimitString = paramObject.get('limit_colors')
  const colorsLimitArray = (colorsLimitString != null)
    ? JSON.parse(colorsLimitString.replaceAll('\'', '"')).map(String)
    : []
  const limitColors = colorsLimitArray.map((color: string) => `#${color.toUpperCase()}`)

  return { providerId, providerIds, apptTypeIds, orgLevel, colors, maxAppt, maxApptColor, maxApptDay, limitColors }
}
