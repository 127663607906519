import { gql } from '@apollo/client'

export const COMPLETE_CHECKOUT = gql`
  mutation CompleteCheckout(
    $apptIds: [ID],
    $firstName: String,
    $lastName: String,
    $email: String,
    $providerId: String,
  ) {
      completeCheckout(
        input: {
          appointment_ids: $apptIds,
          first_name: $firstName,
          last_name: $lastName,
          email: $email,
          provider_id: $providerId,
        }
      ){
          messages {
            field
            message
          }
          appointments {
            id
            title
            contact_type
            date
            end
            start
            timezone_abbr
            provider {
              full_name
            }
          }
      }
  }
`
