import { FC, ReactElement } from 'react'
import styles from './NotFound.module.scss'
import WarningIcon from '../../assets/icons/WarningIcon'
interface IProps {
  error?: String
}
const NotFound: FC<IProps> = ({ error }): ReactElement => {
  return (
        <div className={styles.errorContainer}>
          <WarningIcon width={'50px'} height={'50px'} color={'#FFAF1E'}/>
          <h4>
            {(error != null) ? error : 'Page Not Found'}
          </h4>
        </div>
  )
}
export default NotFound
