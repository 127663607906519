import React, { ReactElement } from 'react'
import { ApolloProvider } from '@apollo/client'
import { Routes, Route } from 'react-router-dom'
import { client } from './client'
import NotFound from './Views/NotFound/NotFound'
import BookSessions from './Views/BookSessions'

const App = (): ReactElement => {
  return (
      <ApolloProvider client={client}>
        <Routes>
            <Route path="/" element={<BookSessions/>} />
            <Route path="/*" element={<NotFound/> } />
        </Routes>
      </ApolloProvider>
  )
}

export default App
