import startOfWeek from 'date-fns/startOfWeek'
import endOfWeek from 'date-fns/endOfWeek'
import sub from 'date-fns/sub'

interface CurrentWeekValue {
  now: Date
  currentStartDate: String
  currentEndDate: String
}

export const useCurrentWeek = (): CurrentWeekValue => {
  const now = new Date()
  const start = startOfWeek(now)
  const initialEnd = endOfWeek(now)
  const end = sub(initialEnd, { days: 0 }) // needed as when converted toISoString changes date to next day.
  const currentStartDate = start.toISOString().split('T')[0]
  const currentEndDate = end.toISOString().split('T')[0]

  return { now, currentStartDate, currentEndDate }
}
